$c-primary: #da1710;
$c-secandary: #da1710;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1710;
$progress-bar-incomplete-overided: #da1710;
$base-font-family: 'SF-Pro', sans-serif;
$font-family-heading: 'SF-Pro', sans-serif;

      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-LightItalic.otf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-Semibold.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-SemiboldItalic.otf');
        font-weight: 500;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.Select {
  position: relative;
  .hasError {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 3px;
    color: $c-danger;
  }
}

.leftPadding30Perc {
  padding: 0.5rem 0.7rem 0.5rem 40% !important;
}

:export {
  primary: var(--primary);
  secondary: var(--secondary);
  primary25: '#fff';
  primary50: lighten($c-secandary, 45%);
  gray: $c-grey;
  bodyGray: #fff;
}
