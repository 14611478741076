$c-primary: #da1710;
$c-secandary: #da1710;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1710;
$progress-bar-incomplete-overided: #da1710;
$base-font-family: 'SF-Pro', sans-serif;
$font-family-heading: 'SF-Pro', sans-serif;

      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-LightItalic.otf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-Semibold.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-SemiboldItalic.otf');
        font-weight: 500;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: var(--page-background-color);

  .body {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    top: 0;
    bottom: 4.8125rem; //45+16*2=77
    left: 0;
    right: 0;
    overflow: auto;
  }

  .backbutton {
    display: flex;
    flex-direction: column;
    width: 2.75rem; //44px
    height: 2.75rem; //44px

    .imgback {
      position: relative;
      width: 1.375rem; //22
      height: 1.375rem; //22
      margin: 0.6875rem; //11
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--page-padding-top) 1.5rem;
    flex: 1;
  }
  .content-force-fill-view-port {
    overflow: hidden;
  }

  .footer {
    position: absolute;
    width: 100%;
    height: 4.8125rem; //45+16*2=77
    padding: 1rem 1.5rem; //16 24
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;
  }

  .footerShadow {
    box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.25);
  }
  .isDark {
    background: #000 !important;
  }
}

.isDark {
  background: #000 !important;
}
