$c-primary: #da1710;
$c-secandary: #da1710;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1710;
$progress-bar-incomplete-overided: #da1710;
$base-font-family: 'SF-Pro', sans-serif;
$font-family-heading: 'SF-Pro', sans-serif;

      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-LightItalic.otf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-Semibold.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SF-Pro';
        src: url('/assets-mobile/fonts/SF-Pro-Display-SemiboldItalic.otf');
        font-weight: 500;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.input {
  display: flex;
  margin-bottom: 1rem;

  .code {
    padding-right: 10px;
  }

  .number {
    input {
      height: auto;
    }
  }
}
